<template>
    <div class="main" :style="{ backgroundImage: `url(${playerBag})` }">
        <div class="player_content">
            <!-- 导航 -->
            <page-head :src="require('@/assets/images/common/nav-back-black.svg')"  class="nav_back" />
            <div class="eval_cont">
                <div class="eval_creator">
                    <img src="../../assets/images/eval/eval-icon-name.svg" alt="" />
                    <span>评估人：</span>
                    <span>{{evalData.creatorName}}</span>
                </div>

                <ul class="tab_btn">
                    <li v-for="(item) in templateTitleInfo"
                        :class="{active:evalKey === item.id}" @click="changeEvalTab(item.id)">
                        <span> {{item.name}}</span>
                    </li>
                </ul>

                <ul class="tab_btn_val clearfix" v-if="evalKey && evalData.evaluationDataMap[evalKey].length">
                    <li :key="item.id" v-for="item in  evalData.evaluationDataMap[evalKey]" class="eval_val" :class="item.evaluationTemplateItem.type">
                        <span class="title">{{item.evaluationTemplateItem.name || ''}}</span>
                        <span>{{item.data || ''}}</span>
                    </li>
                </ul>
                <div v-else class="tab_btn_val" style="display: flex;justify-content: center">
                    <no-data></no-data>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
    import { useRoute} from "vue-router";
    import { defineComponent, onMounted, getCurrentInstance, toRefs, reactive } from 'vue';
    export default defineComponent({
        setup() {
            const route = useRoute();
            const { proxy } = getCurrentInstance();
            const { playerBag, color } = proxy.$Config.UI_CONFIG;
            const { id } = route.query
            const state = reactive({
                playerBag,
                color,
                evalData:{},
                evalKey :'', //默认显示评估tab
                evalBtn:{},
                templateTitleInfo:[], //评估名称集合
            });

            // 获取球员数据
            const getUserData = async () => {
                const params = {
                    id, // 球员id
                }
                const { code, data } = await proxy.$server.getEvaluation(params);
                if (code === 200) {
                   // console.log(data,'data');
                    state.templateTitleInfo = data.evaluationTemplate?.dimensionList;

                   state.evalData = data ;
                   state.evalKey = state.templateTitleInfo[0].id;
                   state.evalBtn = data.evaluationDataMap;
                }
            };

            const changeEvalTab = (key) =>{
                    state.evalKey = key;
            };

            onMounted(() => {
                getUserData();
            });

            return {
                changeEvalTab,
                ...toRefs(state),
            }
        },
    })
</script>

<style scoped lang="scss">
    .main {
        width: 100%;
        height: 100%;
        background-size: 100% auto;
        position: relative;
        box-sizing: border-box;
        padding: 60px 40px;
        .player_content{
            position: relative;
            border-radius: 20px;
            box-sizing: border-box;
            height:100%;
            width: 100%;
            background: url("../../assets/images/common/injury-head-bg.png") no-repeat left top;
            background-size: 100% 300px;
            background-color: #FFFFFF;
            /*返回按钮*/
            .nav_back{
                position: absolute;
                top: 32px;
                left: 32px;
                z-index: 3;
                padding: 0;
            }
            .eval_cont{
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                padding:80px 40px 0 80px ;
                .eval_creator{
                    height: 80px;
                    display: flex;
                    align-items: center;
                    >img{
                        margin-right: 13px;
                    }
                    >span{
                        font-family: PingFangMedium;
                        font-size: 24px;
                        line-height: 80px;
                        color: #B7B7BF;
                        &:last-child{
                            color: #3a3a3a;
                        }
                    }
                }
                .eval_list{
                    padding-bottom: 50px;
                    .eval_list_title{
                        font-family: PingFangMedium;
                        font-size: 24px;
                        line-height: 34px;
                        color: #3A3A3A;
                    }
                    .eval_score{
                        font-family: akrobatBold;
                        font-size: 48px;
                        line-height: 60px;
                        color: #60BF52;
                    }
                }
                .eval_list_score{
                    height: 60px;
                    display: flex;
                    align-items: center;
                }
                pre{
                    font-size: 24px;
                    line-height: 40px;
                    color: #3A3A3A;
                    opacity: 0.68;

                }
                .tab_btn{
                    width: 100%;
                    height: 47px;
                    display: flex;
                    align-items: center;
                    margin: 50px 0 60px 0;
                    >li{
                        width: auto;
                        height: auto;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        >span{
                            display: flex;
                            align-items: center;
                            background: #EEEEEE;
                            padding: 0 16px;
                            line-height: 47px;
                            mix-blend-mode: normal;
                            border-radius: 8px;
                            font-size: 28px;
                            color:rgba(129, 129, 138, 0.4);
                            margin-right: 24px;
                        }
                    }
                    .active{
                        >span{
                            background: #3663E1;
                            color: #FFFFFF;
                        }

                    }
                }
                .tab_btn_val{
                    width: 100%;
                    max-height: calc(100% - 300px);
                    overflow: hidden;
                    overflow-y: auto;
                    &::-webkit-scrollbar{
                        display: none;
                    }
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                    justify-content: flex-start;

                    .eval_val{
                        width: 380px;
                        min-height: 68px;
                        background: #FAFAFA;
                        border-radius: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 17px 32px 17px 16px;
                        margin: 0 50px 50px 0;
                        >span{
                            font-size: 24px;
                            line-height: 34px;
                            color: #81818A;
                            padding-right: 20px;
                            &:last-child{
                                color: #81818A;
                            }
                        }

                        &.input {
                            display: block;
                            width: 100%;

                            span {
                                display: block;
                                color: rgba(58, 58, 58, .68);

                                &.title {
                                    color: #3A3A3A;
                                    font-size: 24px;
                                    margin-bottom: 16px;
                                    font-family: PingFangMedium;
                                    font-weight: bold;
                                }
                            }
                        }

                    }

                }
            }
        }
    }
    .no-data{
        color: #81818A;
    }
</style>

